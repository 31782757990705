import * as React from "react";

function SvgHeartInWhiteCircle(props) {
  return (
    <svg width={24} height={24} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          fill="#FFF"
          opacity={0.9}
          width={23.501}
          height={23.501}
          rx={11.75}
        />
        <path
          d="M17.327 12.25l.008.01-5.585 6.018-5.584-6.019.008-.009c-1.345-1.69-1.254-4.212.209-5.785 1.463-1.573 3.803-1.664 5.367-.21 1.565-1.454 3.905-1.363 5.368.21 1.463 1.573 1.554 4.095.209 5.785z"
          fill="red"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgHeartInWhiteCircle;
