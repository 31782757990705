import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

export const MAIN_CATEGORIES = [
  {
    title: "Antique Furniture",
    url: "antique-furniture",
    image: "antique-furniture.webp",
  },
  {
    title: "Antique Jewellery",
    url: "vintage-jewellery",
    image: "antique-jewellery.webp",
  },
  {
    title: "Mirrors",
    url: "antique-mirrors",
    image: "antique-mirrors.webp",
  },
  {
    title: "Silver",
    url: "antique-silver",
    image: "antique-silver.webp",
  },
  {
    title: "Antique Lighting",
    url: "antique-lighting",
    image: "antique-lighting.webp",
  },
  {
    title: "Chairs",
    url: "antique-chairs",
    image: "most-popular-chairs.webp",
  },
  {
    title: "Glassware",
    url: "antique-glassware",
    image: "antique-glassware.webp",
  },
  {
    title: "Sculptures",
    url: "antique-sculptures",
    image: "antique-sculptures.webp",
  },
  {
    title: "Clocks",
    url: "antique-clocks",
    image: "antique-clocks.webp",
  },
  {
    title: "Ceramics",
    url: "antique-ceramics",
    image: "antique-ceramics.webp",
  },
  {
    title: "Art",
    url: "antique-art",
    image: "antique-art.webp",
  },
  {
    title: "All Antiques",
    url: "antiques",
    image: "all-antique-most-popular.webp",
  }
];

export const HOME_FAQ_LIST = [
  {
    title: "What is the difference between buying antique and vintage?",
    description: <>
      Authentic antique finds are over 100 years old and are rare, as
      they are no longer produced. These antique treasures include those
      from the <Link to="/period/georgian-antiques-(1714-to-1837)">Georgian</Link>,{" "}
      <Link to="/period/victorian-(1837-1901)">Victorian</Link>, and{" "}
      <Link to="/period/edwardian-(1901-1910)">Edwardian</Link> eras.
      Vintage items are those aged 50 to 100 years old.
    </>,
  },
  {
    title: "How do I know if antiques for sale are valuable?",
    description: <>
      If you decide to buy antiques UK dealers are offering, look
      for small marks and signatures that are often placed in an
      obscure spot of the piece. It's also worth noting that every
      antique item is created according to the style of the{" "}
      <Link to="/period">period</Link> during which it was created,{" "}
      so you have to research about the period that interests you,{" "}
      especially when you buy antiques online.
    </>
  },
  {
    title: "What kinds of antiques are worth money?",
    description: <>
      The items to start looking at in antiques stores are{" "}
      <Link to="/antique-furniture">furniture pieces</Link>, as some are extremely rare.
      Other antiques worth splurging on include <Link to="/antique-art">art</Link>,{" "}
      <Link to="/vintage-jewellery">jewellery</Link>, and <Link to="/antique-ceramics">ceramics</Link>,{" "}
      among others. A reputable antique website provides a price range for each antique for sale,{" "}
      giving you an idea of how lucrative this type of investment can be.
    </>
  },
  {
    title: "What are the most sought after antiques?",
    description: <>
      UK antique collectors most frequently buy <Link to="/antique-furniture">antique furniture</Link>,{" "}
      silverware, <Link to="/vintage-watches">watches</Link>, <Link to="/antique-clocks">clocks</Link>,{" "}
      <Link to="/antique-mirrors">mirrors</Link>, <Link to="/antique-chairs">chairs</Link> and more. Should
      you decide to shop antiques online, UK dealers have to ensure the store or dealer that is selling antiques
      is an established one, to avoid ending up with counterfeit items.
    </>
  }
];

export const KNOWLEDGE_LINKS = [
  {
    title: "The Latest Antiques and Collectables from our Antique Store",
    description: <>
      Above you can see some of the latest items to be added to our website by
      our certified dealers. Whether you are looking for{" "}
      <Link to="/period/georgian-antiques-(1714-to-1837)">Georgian antiques</Link>,{" "}
      <Link to="/period/victorian-(1837-1901)">Victorian antiques</Link>,{" "}
      <Link to="/period/edwardian-(1901-1910)">Edwardian antiques</Link>,{" "}
      or even <Link to="/period/art-deco">Art Deco antiques</Link>,{" "} you will
      easily be able to find something that is going to suit your requirements,
      taste, as well as your budget. With so many high-quality antiques for sale,
      the one trouble that you may have is choosing which one to buy. Using our
      website allows you to purchase from top antiques dealers in London, or anywhere
      else in the country, so gives you access to a wide selection of stock.
    </>,
  },
  {
    title: "Explore Antiques for sale at LoveAntiques.com",
    description: <>
      There are many ways you can use LoveAntiques.com to find that certain
      antique item you are looking for. You can browse through our extensive
      antique category list or you can be more specific. Search by:
      <ul className="home--text-list">
        <li>
          <Link to="/directory/dealers" className="home--knowledge-link">
            <b>Dealer Directory</b>
          </Link>
        </li>
        <li>
          <Link to="/period" className="home--knowledge-link">
            <b>Period </b>
          </Link>
              such as
          <Link to="/period/regency-(1811-1820)" className="home--info-link">
            {" Regency "}
          </Link>
              or
          <Link to="/period/art-nouveau-(1890-1910)" className="home--info-link">
            {" Art Nouveau"}
          </Link>
        </li>
        <li>
          <Link to="/knowledge/material" className="home--knowledge-link">
            <b>Material </b>
          </Link>
            such as
          <Link to="knowledge/material/mahogany" className="home--info-link">
            {" Mahogany"}
          </Link>
            ,
          <Link to="knowledge/material/silver" className="home--info-link">
            {" Silver "}
          </Link>
            or
          <Link to="knowledge/material/porcelain" className="home--info-link">
            {" Porcelain"}
          </Link>
        </li>
        <li>
          <Link to="/knowledge/origin" className="home--knowledge-link">
            <b>Origin </b>
          </Link>
            such as
          <Link to="/knowledge/origin/japanese" className="home--info-link">
            {" Japanese "}
          </Link>
            or
          <Link to="/knowledge/origin/london-assay-office" className="home--info-link">
            {" London Assay Office"}
          </Link>
        </li>
        <li>
          <Link to="/knowledge/artisan" className="home--knowledge-link">
            <b>Artisan </b>
          </Link>
            such as
          <Link to="/knowledge/artisan/royal-doulton-maker" className="home--info-link">
            {" Royal Doulton "}
          </Link>
            or
          <Link to="/knowledge/artisan/wedgwood-designer" className="home--info-link">
            {" Wedgwood"}
          </Link>
        </li>
      </ul>
      <Link to="/knowledge">
        <Button className="home--info-button" size="large">
          Explore Now
        </Button>
      </Link>
    </>,
  },
  {
    title: "About LoveAntiques.com - what we do and why you should trust us?",
    description: `
      LoveAntiques.com is the premier online antiques website connecting genuine antiques
      from trusted members from across the UK. Our fully comprehensive antiques store
      contains thousands of rare and unique items from dealers giving you the chance to
      purchase the best condition vintage-style items worldwide. Offering high-quality
      antiques and decorative items, often only seen at your local antique shop, roadshow,
      or antique fairs. Browse and buy antiques with complete confidence at LoveAntiques.com,
      all our dealers have been fully vetted, so you can rest assured that you are purchasing
      genuine antiques and you can pay for them using a secure method of payment.
    `,
  },
  {
    title: "What to consider when buying antiques in the UK?",
    description: `
      One thing that you can be assured of when purchasing from the
      antique dealers that trade on our website is that they are all
      appropriately vetted, as well as each item they place on the site
      for sale. There are a few things that you will want to consider
      before purchasing antiques, such as age, condition, rarity,
      collectability, and provenance. All these factors can influence the
      value of an item, so are questions that you should ask the antique
      dealers online before making your purchase. You may also wish to look
      at the latest trends for antiques in the UK, which will help you to
      gauge how much demand there is for an item in the current marketplace.
    `,
  }
];
