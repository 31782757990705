import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// components
import HomeMobileIconContainer from "../../shared/HomeMobileIconContainer";

// icons
import LargeAudienceIcon from "../../Icons/LargeAudienceIcon";
import DiamondIcon from "../../Icons/DiamondIcon";
import ChatsIcon from "../../Icons/ChatsIcon";
import SecureIcon from "../../Icons/SecureIcon";
import ReliableIcon from "../../Icons/ReliableIcon";
import WorldWideIcon from "../../Icons/WorldWideIcon";
import BullhornIcon from "../../Icons/BullhornIcon";
import EyeIcon from "../../Icons/EyeIcon";
import UserPlus from "../../Icons/UserPlus";

const HOME_PAGE_URL = "/profile/home";

const JoinUsSection = ({ isAuthenticated }) => {
  return (
    <section className="home--join" data-testid="home-join-section">
      <div className="page-container">
        <p className="home--join-title">
          Join
          <br />
          <span className="highlight">Love</span>Antiques
          <span className="highlight">.com</span>
        </p>

        <p className="home--info">
          LoveAntiques.com is perfect for both collectors and antique dealers in
          the UK to buy and sell a wide assortment of different rare antiques.
          There are many benefits from using our website for both collectors and
          dealers:
        </p>

        <Row gutter={[30, 30]}>
          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As a <span className="highlight">Collector</span> - Why join and
              how to buy antiques online on LoveAntiques.com?
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <p className="read-more-text">
                  Buying from LoveAntiques.com offers collectors a vast
                  selection of antiques across the UK and beyond. You can
                  contact dealers directly, ask questions, and securely pay via
                  card or PayPal. Every dealer is thoroughly vetted, ensuring
                  you're dealing with reputable sellers.
                </p>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated
                        ? "/registration/collector"
                        : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Collector
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<DiamondIcon />}
                  iconTitle="Largest Antique Collection"
                />
                <HomeMobileIconContainer
                  iconNode={<ChatsIcon />}
                  iconTitle="Easy to contact dealer"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<ReliableIcon />}
                  iconTitle="Reliable antique dealers"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={
                    !isAuthenticated ? "/registration/collector" : HOME_PAGE_URL
                  }
                >
                  <Button className="home--join-button" size="large">
                    Join as Collector
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As a <span className="highlight">Dealer</span> - Why join and how
              to list your antiques for sale online in UK
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <p className="read-more-text">
                  LoveAntiques.com is the ideal platform for dealers to sell
                  vintage and antique items globally. After approval, upload
                  your products, and we'll optimize them for SEO. Your items
                  reach thousands, with direct customer contact and payments.
                  Grow your business with LoveAntiques.com
                </p>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Join as Dealer
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<WorldWideIcon />}
                  iconTitle="Worldwide scale"
                />
                <HomeMobileIconContainer
                  iconNode={<ChatsIcon />}
                  iconTitle="Easy to contact buyers"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<LargeAudienceIcon />}
                  iconTitle="Large audience"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={
                    !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                  }
                >
                  <Button className="home--join-button" size="large">
                    Join as Dealer
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <h2 className="h2 home--join-info">
              As an <span className="highlight">Antiques Business</span> - Why
              register and how to promote your business on LoveAntiques.com?
            </h2>

            <Col xs={0} sm={24} className="p-0">
              <div>
                <p className="read-more-text">
                  Registering with LoveAntiques.com expands your reach to
                  thousands of potential customers. Whether you choose a free or
                  premium listing, your business will be featured in our
                  directory, making it easy for antique enthusiasts to find you.
                  Showcase your unique items, connect with passionate buyers,
                  and boost your sales by joining LoveAntiques.com.
                </p>

                <div className="home--join-button-container">
                  <Link
                    to={
                      !isAuthenticated ? "/registration/dealers" : HOME_PAGE_URL
                    }
                  >
                    <Button className="home--join-button" size="large">
                      Register your Business
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={0}>
              <div className="home--join--mobile-container">
                <HomeMobileIconContainer
                  iconNode={<BullhornIcon />}
                  iconTitle="Promote your business"
                />
                <HomeMobileIconContainer
                  iconNode={<EyeIcon />}
                  iconTitle="Increase visibility"
                />
                <HomeMobileIconContainer
                  iconNode={<SecureIcon />}
                  iconTitle="Secure Payments"
                />
                <HomeMobileIconContainer
                  iconNode={<UserPlus />}
                  iconTitle="Connect with customers"
                />
              </div>
              <div className="home--join-button-container">
                <Link
                  to={!isAuthenticated ? "/registration/trader" : HOME_PAGE_URL}
                >
                  <Button className="home--join-button" size="large">
                    Register your Business
                  </Button>
                </Link>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={12} md={12} xl={6}>
            <p className="h2 home--join-info">More Information?</p>

            <div style={{ marginBottom: "20px" }}>
              <p className="read-more-text">
                If you want to know more about how the site works, or how to
                join as a collector or a dealer, send us an email to
                info@loveantiques.com, or use our online contact form. If you
                love to buy or sell antiques, then LoveAntiques.com is the place
                for you!
              </p>

              <div className="flex home--join-button-container">
                <Link to="/contact-us">
                  <Button className="home--join-button" size="large">
                    Ask a Question
                  </Button>
                </Link>

                <Link to="/faq">
                  <Button
                    className="home--join-button home--join-button_right"
                    size="large"
                    ghost
                  >
                    View FAQ
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

JoinUsSection.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default connect((state) => ({
  isAuthenticated: state.auth.isAuthenticated,
}))(JoinUsSection);
