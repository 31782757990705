import { Col, Row } from "antd";
import React from "react"

// constants
import { KNOWLEDGE_LINKS } from "../constants";

const KnowledgeLinksSection = () => {
  return (
    <section className="home--block" data-testid="home-knowledge-links">
      <div className="page-container">
        <Row gutter={[30, 30]}>
          {KNOWLEDGE_LINKS.map((item, index) => (
            <Col
              md={12}
              xs={24}
              key={`faq-${index}`}
              data-testid="lant-knowledge"
            >
              <h2 className="h2" data-testid="lant-knowledge__title">
                {item.title}
              </h2>
              <div
                className="home--text"
                data-testid="lant-knowledge__description"
              >
                {item.description}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}

export default KnowledgeLinksSection