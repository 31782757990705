import React from "react"

// constants
import { HOME_FAQ_LIST } from "../constants";

const FAQSection = () => {
  return (
    <section className="home--block" data-testid="home-faq-container">
      <div className="home--faq-container">
        <p className="h2">BUYING ANTIQUES ONLINE FAQs</p>
        {HOME_FAQ_LIST.map((item, index) => (
          <React.Fragment key={`HOME_FAQ_LIST-${index}`}>
            <h3 className="h3" data-testid="lant-faq-title">
              {`Q: ${item.title}`}
            </h3>
            <div
              className="static-page--content-block-text"
              data-testid="lant-faq-description"
            >
              {item.description}
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}

export default FAQSection